
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { FeatureName } from './feature';
import { transferControlReducer } from './reducer';
import { TransferControlStoreEffects } from './effects';


@NgModule({
	imports: [
		CommonModule,
		StoreModule.forFeature(FeatureName, transferControlReducer),
		EffectsModule.forFeature([TransferControlStoreEffects])
	]
})

export class TransferControlStoreModule { }
