import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { InsuranceDecisionState, TransferState, TransferSurveyType } from 'apps/middle/src/app/transfers-module/enums';
import { IDebtorPanelInformation, IInsuranceDecision, IInvoiceField, ISellerPanelInformation, ITransfer, ITransferDocumentEntity, ITransferSurvey, ITransferSurveyRow } from 'apps/middle/src/app/transfers-module/models';
import { ISellerIdentity } from 'apps/middle/src/app/sellers-module/models';
import { ControlValidationState } from 'apps/middle/src/app/shared-module/enums';
import { asInvoiceField, sortDocumentEntities } from 'apps/middle/src/app/transfers-module/functions';
import { UserClearanceLevel } from 'apps/middle/src/app/authentication-module/enums';
import { KycState, SellerStatus } from 'apps/middle/src/app/sellers-module/enums';
import { IUIMessage } from '@aston/foundation';

import { selectIsSurveyMandatory } from '../factor-features-store/selectors';
import { createEntitySliceSelectors } from '../functions';
import { IAppState } from '../app-store/state';
import { getCurrentUserClearsLevel, selectState as selectAppState, selectCreditInsuranceEnabled } from '../app-store/selectors';

import { FeatureName } from './feature';
import { ITransferControlState } from './state';



export const selectState: MemoizedSelector<object, ITransferControlState> = createFeatureSelector<ITransferControlState>(FeatureName);

const statusFromItems = (items: IInvoiceField<any>[], allowNonMandatory = false, allowEmpty = false): TransferState => {
	if (!allowEmpty && ( !items || !items.length )) {
		return TransferState.PendingReview;
	} else if (items.find(d => d.state === ControlValidationState.Refused)) {
		return TransferState.Rejected;
	} else if (items.every(d => d.state === ControlValidationState.Validated || ( allowNonMandatory && !d.value.name && !d.value.mandatory ))) {
		return TransferState.Valid;
	}
	return TransferState.PendingReview;
}

const isLoading = (state: ITransferControlState): boolean => state.isLoading;
const hasError = (state: ITransferControlState): boolean => state.error;
const getTransfer = (state: ITransferControlState): ITransfer => state.transfer.entity;
const getNotification = (state: ITransferControlState): IUIMessage => state.notification;
const getSellerIsBlocked = (state: ITransferControlState): boolean => getSellerIdentity(state) && state.sellerIdentity.entity.status === SellerStatus.Blocked;
const getManualSurveys = (state: ITransferControlState): IInvoiceField<ITransferSurveyRow[]> => asInvoiceField(
	state.surveys.entity.value.filter(s => s.type === TransferSurveyType.Email || s.type === TransferSurveyType.Phone),
	state.surveys.entity.state);

const getSuperDebtorAutomaticSurvey = (state: ITransferControlState): ITransferSurveyRow => {
	const automaticSurveys = state.surveys.entity.value.filter(s => s.type === TransferSurveyType.AutomaticEmail);
	return automaticSurveys.length ? automaticSurveys[0] : null;
};

const getIsPending = (state: ITransferControlState): boolean => state.isPending;
const getIsDisabled = (state: ITransferControlState, appState: IAppState): boolean => {
	if (getIsPending(state)) {
		return true;
	}
	if (!getTransfer(state)) {
		return true;
	}
	const inFinalState = isFinalState(state.transfer.entity.globalStatus);
	const allowed = getCurrentUserClearsLevel(appState, UserClearanceLevel.TransferValidation);

	return getKycIsPending(state) || getSellerIsBlocked(state) || inFinalState || !allowed;
};
const getKycIsPending = (state: ITransferControlState): boolean => getSellerIdentity(state) && state.sellerIdentity.entity.kycStatus !== KycState.Validated;
const getSurveyDetails = (state: ITransferControlState, id): ITransferSurvey => state.surveysDetails[ id ];

const getEntities = (state: ITransferControlState): IInvoiceField<ITransferDocumentEntity>[] => sortDocumentEntities(state.invoices.entity);
const getSellerIdentity = (state: ITransferControlState): ISellerIdentity => state.sellerIdentity.entity;
const getSellerInfos = (state: ITransferControlState): ISellerPanelInformation => state.panelInfos.entity && state.panelInfos.entity.seller;
const getDebtorInfos = (state: ITransferControlState): IDebtorPanelInformation => state.panelInfos.entity && state.panelInfos.entity.debtor;
const isCoverageAcceptedByInsuranceProvider = (ciEnabled: boolean, state: IInsuranceDecision): boolean => {
	if (!ciEnabled) {
		return true;
	}
	return state && (
		state.coverageStatus === InsuranceDecisionState.Activated ||
		state.coverageStatus === InsuranceDecisionState.Pending ||
		state.coverageStatus === InsuranceDecisionState.Settled );
};

const getInsuranceDecisionIsMissing = (ciEnabled: boolean, state: ITransferControlState): boolean => {
	return ciEnabled && state.transfer && !state.insuranceDecision.entity;
};

const getStep1Status = (ciEnabled: boolean, state: ITransferControlState): TransferState => {
	if (!ciEnabled) {
		return TransferState.Valid;
	}
	if (state.insuranceDecision.entity) {
		if (state.insuranceDecision.entity.validationStatus === ControlValidationState.Validated) {
			return TransferState.Valid;
		} else if (state.insuranceDecision.entity.validationStatus === ControlValidationState.Refused) {
			return TransferState.Rejected;
		}
	}
	return TransferState.PendingReview;
};

const getStep2Status = (state: ITransferControlState): TransferState => {
	return statusFromItems(state.invoices.entity.reduce((acc, document) => acc.concat(document.value.documentFiles), []));
};

const getStep3Status = (state: ITransferControlState, isSurveyMandatory: boolean): TransferState => {
	if (! isSurveyMandatory) return TransferState.Valid;
	if (state.surveys) {
		if (state.surveys.entity.state === ControlValidationState.Validated) {
			return TransferState.Valid;

		} else if (state.surveys.entity.state === ControlValidationState.Refused) {
			return TransferState.Rejected;
		}
	}
	return TransferState.PendingReview;
};

const getStep4Status = (ciEnabled: boolean, state: ITransferControlState, isSurveyMandatory: boolean): TransferState => {
	return [
		getStep1Status(ciEnabled, state),
		getStep2Status(state),
		getStep3Status(state, isSurveyMandatory)
	].find(s => s !== TransferState.Valid) ? TransferState.PendingProcess : TransferState.Valid;
};

const getGlobalState = (state: ITransferControlState): TransferState => {
	return getTransfer(state) ? getTransfer(state).globalStatus : TransferState.PendingReview;
};

const isFinalState = (state: TransferState): boolean => {
	return state === TransferState.Archived
		|| state === TransferState.Rejected
		|| state === TransferState.Valid
		;
};

const getGlobalStateAllowedStatus = (ciEnabled: boolean, state: ITransferControlState, isSurveyMandatory: boolean): TransferState[] => {
	if (!getTransfer(state)) {
		return [];
	}
	if (isFinalState(state.transfer.entity.globalStatus)) {
		return [state.transfer.entity.globalStatus];
	}
	const allStepStatus = [
		getStep1Status(ciEnabled, state),
		getStep2Status(state),
		getStep3Status(state, isSurveyMandatory),
		getStep4Status(ciEnabled, state, isSurveyMandatory)
	];
	if (allStepStatus.every(s => s === TransferState.Valid)) {
		return [TransferState.PendingReview, TransferState.Valid, TransferState.Rejected];
	}
	if (allStepStatus.find(s => s === TransferState.Rejected)) {
		return [TransferState.PendingReview, TransferState.Rejected];
	}
	if (state.transfer.entity.globalStatus === TransferState.PendingProcess) {
		return [TransferState.PendingProcess, TransferState.PendingReview, TransferState.Rejected];
	}
	return [TransferState.PendingReview, TransferState.Rejected];
};

export const {
	selectEntity: selectInsuranceDecision,
	selectError: selectRetryInsuranceError,
	selectIsLoading: selectInsuranceIsLoading,
} = createEntitySliceSelectors(createSelector(selectState, state => state.insuranceDecision));

export const {
	selectEntity: selectTransfer,
	selectError: selectTransferStatusError,
	selectIsLoading: selectTransferIsLoading,
} = createEntitySliceSelectors(createSelector(selectState, state => state.transfer));

export const {
	selectEntity: selectSellerIdentity,
	selectError: selectSellerIdentityError,
	selectIsLoading: selectSellerIdentityIsLoading,
} = createEntitySliceSelectors(createSelector(selectState, state => state.sellerIdentity));

export const surveySelectors = {
	...createEntitySliceSelectors(createSelector(selectState, state => state.surveys)),
	selectManualSurveys: createSelector(selectState, selectAppState, getManualSurveys),
	selectSuperDebtorAutomaticSurvey: createSelector(selectState, selectAppState, getSuperDebtorAutomaticSurvey),
};

export const {
	selectEntity: selectSurveys,
	selectError: selectSurveysError,
	selectIsLoading: selectSurveysIsLoading,
	selectManualSurveys: selectManualSurveys,
	selectSuperDebtorAutomaticSurvey: selectSuperDebtorAutomaticSurvey
} = surveySelectors;

const getAvailableStepIds = (ciEnabled: boolean): { [key: string]: number } => {
	const tabs = ['s1', 's2', 's3', 's4']
	.filter(s => {
		// plug here special transfer step logic

		// no step 1 if there is no CI
		if (s === 's1' && !ciEnabled) {
			return false;
		}

		return true;
	})
	.reduce((acc, s) => {
		return { ...acc, [ s ]: Object.keys(acc).length };
	}, {});

	return tabs;
};

export const {
	selectEntity: selectPanelInfos,
} = createEntitySliceSelectors(createSelector(selectState, state => state.panelInfos));

export const {
	selectEntity: selectSurveyForm,
	selectError: selectSurveyFormError,
	selectIsLoading: selectSurveyFormIsLoading,
} = createEntitySliceSelectors(createSelector(selectState, state => state.surveyForm));

const getSurveyShowForm = (state: ITransferControlState): boolean => {
	return state.surveyShowForm;
};

export const selectIsLoading = createSelector(selectState, isLoading);
export const selectHasError = createSelector(selectState, hasError);
export const selectInvoices = createSelector(selectState,
	getEntities);
export const selectNotification = createSelector(selectState, getNotification);
export const selectSellerIsBlocked = createSelector(selectState, selectAppState, getSellerIsBlocked);
export const selectKycIsPending = createSelector(selectState, selectAppState, getKycIsPending);
export const selectIsPending = createSelector(selectState, selectAppState, getIsPending);
export const selectIsDisabled = createSelector(selectState, selectAppState, getIsDisabled);
export const selectSurvey = (props) => createSelector(selectState,	state => getSurveyDetails(state, props.id));
export const selectSurveyShowForm = createSelector(selectState, getSurveyShowForm);
export const selectSellerInfos = createSelector(selectState, getSellerInfos);
export const selectDebtorInfos = createSelector(selectState, getDebtorInfos);
export const selectGlobalState = createSelector(selectState, getGlobalState);
export const selectGlobalStateAllowedStatus = createSelector(selectCreditInsuranceEnabled, selectState, selectIsSurveyMandatory, getGlobalStateAllowedStatus);
export const selectStep1Status = createSelector(selectCreditInsuranceEnabled, selectState, getStep1Status);
export const selectStep2Status = createSelector(selectState, getStep2Status);
export const selectStep3Status = createSelector(selectState, selectIsSurveyMandatory, getStep3Status);
export const selectStep4Status = createSelector(selectCreditInsuranceEnabled, selectState, selectIsSurveyMandatory, getStep4Status);
export const selectInsuranceDecisionIsMissing = createSelector(selectCreditInsuranceEnabled, selectState, getInsuranceDecisionIsMissing);
export const selectIsCoverageAcceptedByInsuranceProvider: MemoizedSelector<object, any> = createSelector(selectCreditInsuranceEnabled, selectInsuranceDecision, isCoverageAcceptedByInsuranceProvider);
export const selectAvailableStepIds: MemoizedSelector<object, { [ key: string ]: number }> = createSelector(selectCreditInsuranceEnabled, selectState, getAvailableStepIds);

export const {
	selectEntity: selectDebtorContacts,
	selectError: selectDebtorContactsError,
	selectIsLoading: selectDebtorContactsLoading,
} = createEntitySliceSelectors(createSelector(selectState, state => state.debtorContacts));
