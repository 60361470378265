import { asInvoiceField } from 'apps/middle/src/app/transfers-module/functions';
import { createReducer, on } from '@ngrx/store';
import { ITransferSurveyRow } from 'apps/middle/src/app/transfers-module/models';

import { withEntityReducer } from '../functions';

import { initialState, ITransferControlState } from './state';
import * as featureActions from './actions';

export const transferControlReducer = createReducer<ITransferControlState>(
	initialState,
	...withEntityReducer<ITransferControlState>('transfer', featureActions.transferActions),
	...withEntityReducer<ITransferControlState>('invoices', featureActions.transferEntitiesActions),
	...withEntityReducer<ITransferControlState>('insuranceDecision', featureActions.insuranceDecisionActions),
	...withEntityReducer<ITransferControlState>('sellerIdentity', featureActions.sellerIdentityActions),
	...withEntityReducer<ITransferControlState>('surveys', featureActions.surveysListActions),
	...withEntityReducer<ITransferControlState>('panelInfos', featureActions.panelInfosActions),
	...withEntityReducer<ITransferControlState>('surveyForm', featureActions.surveyEntityActions),
	...withEntityReducer<ITransferControlState>('debtorContacts', featureActions.debtorContactsActions),


	on(featureActions.Notify, (state, action) => ({
		...state,
		notification: action.message,
	})),

	on(featureActions.LoadTransferRequest, () => ({
		...initialState,
		isPending: true,
		isLoading: true
	})),

	on(featureActions.TransferFullyLoaded, (state) => ({
		...state,
		isPending: false,
		isLoading: false
	})),

	on(featureActions.UpdateTransferGlobalStatusRequest, (state) => ({
		...state,
		isPending: true
	})),

	on(featureActions.UpdateTransferGlobalStatusFailure, (state, action) => ({
		...state,
		isPending: false,
		transferStatusError: action.error.message
	})),

	on(featureActions.UpdateTransferGlobalStatusSuccess, (state, action) => ({
		...state,
		isPending: false,
		transferStatusError: null,
		transfer: {
			...state.transfer,
			entity: {
				...state.transfer.entity,
				globalStatus: action.entity
			}
		}
	})),

	on(featureActions.RetryInsuranceDecisionError, (state, action) => ({
		...state,
		insuranceDecision: {
			...state.insuranceDecision,
			error: action.error
		}
	})),

	on(featureActions.LoadTransferSuccess, (state, action) => ({
		...state,
		surveys: {
			...state.surveys,
			entity: {
				...state.surveys.entity,
				state: action.entity.surveysStatus
			}
		}
	})),

	on(featureActions.ValidateInsuranceDecisionSuccess, (state, action) => ({
		...state,
		insuranceDecision: {
			...state.insuranceDecision,
			entity: {
				...state.insuranceDecision.entity,
				validationStatus: action.state
			}
		}
	})),

	on(featureActions.ValidateInvoiceDocumentRequest, (state, action) => {
		const requestedEntity = state.invoices.entity.find(e => !! e.value.documentFiles.find(d => d.value.id === action.document.id));
		if (! requestedEntity) {
			return state;
		}
		requestedEntity.value.documentFiles = requestedEntity.value.documentFiles.map(document => document.value.id === action.document.id ? {...document, isLoading: true } : document);
		const requestedTransferEntities = state.invoices.entity.filter(e => e.value !== requestedEntity.value).concat(requestedEntity);
		return {
			...state,
			isPending: true,
			invoices: {
				...state.invoices,
				entity: requestedTransferEntities
			}
		};
	}),

	on(featureActions.ValidateInvoiceDocumentSuccess, (state, action) => {
		const modifiedEntity = state.invoices.entity.find(e => !! e.value.documentFiles.find(d => d.value.id === action.document.id));
		if (! modifiedEntity) {
			return state;
		}
		modifiedEntity.value.documentFiles = modifiedEntity.value.documentFiles.map(document => document.value.id === action.document.id ? {...document, state: action.state, isLoading: false } : document);
		const modifiedTransferEntities = state.invoices.entity.filter(e => e.value !== modifiedEntity.value).concat(modifiedEntity);
		return {
			...state,
			isPending: false,
			invoices: {
				...state.invoices,
				entity: modifiedTransferEntities
			}
		};
	}),

	on(featureActions.ValidateInvoiceDocumentFailure, (state) => ({
		...state,
		isPending: false,
		invoices: {
			...state.invoices,
			entity: state.invoices.entity.map(item => ({
				...item,
				value: {
					...item.value,
					isLoading: false,
					documentFiles: item.value.documentFiles.map(d => ({ ...d, isLoading: false }))
				}
			}))
		}
	})),

	on(featureActions.LoadSurveySuccess, (state, action) => ({
		...state,
		surveysDetails: { ...state.surveysDetails, [action.entity.id]: action.entity }
	})),

	on(featureActions.CreateSurveySuccess,
		featureActions.CreateSurveyFailure,
		featureActions.UpdateSurveySuccess,
		featureActions.UpdateSurveyFailure,
		featureActions.ValidateSurveysSuccess,
		featureActions.ValidateSurveysFailure,
		state => ({
		...state,
		isPending: false
	})),

	on(featureActions.UpdateSurveyRequest,
		featureActions.ValidateSurveysRequest,
		state => ({
		...state,
		isPending: true
	})),

	on(featureActions.UpdateSurveyRequest, (state, action) => ({
		...state,
		surveysDetails: { ...state.surveysDetails, [action.entity.id]: null }
	})),

	on(featureActions.ValidateSurveysSuccess, (state, action) => ({
		...state,
		surveys: {
			...state.surveys,
			entity: asInvoiceField<ITransferSurveyRow[]>(state.surveys.entity.value, action.state)
		}
	})),

	on(featureActions.PreviewSurveyEmail, state => ({
		...state,
		surveyForm: {
			...state.surveyForm,
			// Preview is not asynchronous => no need to block the UI
			isLoading: false
		}
	})),

	on(featureActions.SetSurveyShowForm, (state, action) => ({
		...state,
		surveyShowForm: action.param
	})),
);
