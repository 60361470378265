
import { createAction, props } from '@ngrx/store';
import { TransferState, TransferSurveyType } from 'apps/middle/src/app/transfers-module/enums';
import { ISellerIdentity } from 'apps/middle/src/app/sellers-module/models';
import { ControlValidationState } from 'apps/middle/src/app/shared-module/enums';
import { ITransfer, IInsuranceDecision, ITransferDocumentEntity, ITransferDocument, ITransferSurvey, IInvoiceField, ITransferSurveyRow, IPanelInformation } from 'apps/middle/src/app/transfers-module/models';
import { IUIMessage } from '@aston/foundation';
import { IEmailPreview } from 'apps/middle/src/app/shared-module/models';
import { IDebtorContact } from 'apps/middle/src/app/debtors-module/models';

import { createEntityActions, createTypedAction, EntityActionType, createErrorAction, createDebugAction, createActionWithSingleProps } from '../functions';
import { CorrelationParams, newAggregationId } from '../models/correlated-actions.model';

const ActionPrefix = 'TransferControl';

export const Notify = createAction(
	`[${ActionPrefix}] Notify`,
	props<{ message: IUIMessage }>()
);

export const Debug = createDebugAction(`[${ActionPrefix}] Debug`);

export const transferActions = {
	...createEntityActions<ITransfer>(ActionPrefix, 'Transfer'),
	LoadEntityByIdRequest: createTypedAction(
		EntityActionType.LoadEntityRequest,
		`[${ActionPrefix}] Load Transfer By ID Request`,
		(id: number, sellerId: number, correlationParams: CorrelationParams = { correlationId: newAggregationId() }) => ( {
			id, sellerId, correlationParams
		} )
	),
	TransferFullyLoaded: createAction(`[${ActionPrefix}] Transfer Fully Loaded`)
};
export const {
	LoadEntityByIdRequest: LoadTransferRequest,
	LoadEntitySuccess: LoadTransferSuccess,
	LoadEntityFailure: LoadTransferFailure,
	TransferFullyLoaded
} = transferActions;

export const transferGlobalStatusActions = {
	...createEntityActions<TransferState>(ActionPrefix, 'Transfer Global Status'),
	UpdateEntityRequest: createTypedAction(
		EntityActionType.UpdateEntityRequest,
		`[${ActionPrefix}] Update Transfer Global Status Request`,
		(state: TransferState, correlationParams: CorrelationParams = { correlationId: newAggregationId() }) => ( {
			state, correlationParams
		} )
	),
};
export const {
	UpdateEntityRequest: UpdateTransferGlobalStatusRequest,
	UpdateEntitySuccess: UpdateTransferGlobalStatusSuccess,
	UpdateEntityFailure: UpdateTransferGlobalStatusFailure,
} = transferGlobalStatusActions;

export const panelInfosActions = createEntityActions<IPanelInformation>(ActionPrefix, 'Panel Infos');
export const {
	LoadEntityByIdRequest: LoadPanelInformationRequest,
	LoadEntitySuccess: LoadPanelInformationSuccess,
	LoadEntityFailure: LoadPanelInformationFailure,
} = panelInfosActions;

export const sellerIdentityActions = createEntityActions<ISellerIdentity>(ActionPrefix, 'Seller Identity');
export const {
	LoadEntityByIdRequest: LoadSellerIdentityRequest,
	LoadEntitySuccess: LoadSellerIdentitySuccess,
	LoadEntityFailure: LoadSellerIdentityFailure,
} = sellerIdentityActions;

export const insuranceDecisionActions = {
	...createEntityActions<IInsuranceDecision>(ActionPrefix, 'Insurance Decision'),
	ValidateInsuranceDecisionRequest: createAction(
		`[${ActionPrefix}] Validate Insurance Decision Request`,
		props<{ insuranceDecision: IInsuranceDecision, state: ControlValidationState }>()
	),
	ValidateInsuranceDecisionSuccess: createAction(
		`[${ActionPrefix}] Validate Insurance Decision Success`,
		props<{ insuranceDecision: IInsuranceDecision, state: ControlValidationState }>()
	),
	RetryInsuranceDecisionRequest: createAction(
		`[${ActionPrefix}] Retry Insurance Decision Request`,
		props<{ transferId: number }>()
	),
	RetryInsuranceDecisionError: createErrorAction(
		`[${ActionPrefix}] Retry Insurance Decision Error`,
	),
};
export const {
	LoadEntityByIdRequest: LoadInsuranceDecisionRequest,
	LoadEntitySuccess: LoadInsuranceDecisionSuccess,
	LoadEntityFailure: LoadInsuranceDecisionFailure,
	ValidateInsuranceDecisionRequest,
	ValidateInsuranceDecisionSuccess,
	RetryInsuranceDecisionRequest,
	RetryInsuranceDecisionError,
} = insuranceDecisionActions;

export const ValidateInvoiceDocumentRequest = createAction(
	`[${ActionPrefix}] Validate Invoice Document Request`,
	props<{ entity: ITransferDocumentEntity, document: ITransferDocument, state: ControlValidationState }>()
);
export const ValidateInvoiceDocumentSuccess = createAction(
	`[${ActionPrefix}] Validate Invoice Document Success`,
	props<{ entity: ITransferDocumentEntity, document: ITransferDocument, state: ControlValidationState }>()
);
export const ValidateInvoiceDocumentFailure = createAction(
	`[${ActionPrefix}] Validate Invoice Document Failure`,
);

export const surveysListActions = {
	...createEntityActions<IInvoiceField<ITransferSurveyRow[]>>(ActionPrefix, 'Surveys List'),
	ValidateSurveysRequest: createAction(
		`[${ActionPrefix}] Validate Surveys Request`,
		props<{ state: ControlValidationState }>()
	),
	ValidateSurveysSuccess: createAction(
		`[${ActionPrefix}] Validate Surveys Success`,
		props<{ state: ControlValidationState }>()
	),
	ValidateSurveysFailure: createErrorAction(
		`[${ActionPrefix}] Validate Surveys Failure`,
	),
};
export const {
	LoadEntityByIdRequest: LoadSurveysRequest,
	LoadEntitySuccess: LoadSurveysSuccess,
	LoadEntityFailure: LoadSurveysFailure,
	ValidateSurveysRequest,
	ValidateSurveysFailure,
	ValidateSurveysSuccess
} = surveysListActions;

export const surveyEntityActions = {
	...createEntityActions<ITransferSurvey>(ActionPrefix, 'Survey'),
	LoadEntityByIdRequest: createTypedAction(
		EntityActionType.LoadEntityRequest,
		`[${ActionPrefix}] Load Survey By ID Request`,
		(id: number, surveyType: TransferSurveyType, correlationParams: CorrelationParams = { correlationId: newAggregationId() }) => ( {
			id, surveyType, correlationParams
		} )
	),

	PreviewSentSurveyRequest: createAction(
		`[${ActionPrefix}] Preview Sent Survey Request`,
		(entity: ITransferSurvey, correlationParams: CorrelationParams = { correlationId: newAggregationId() }) => ( {
			entity, correlationParams
		} )
	),
};

export const {
	LoadEntityByIdRequest: LoadSurveyRequest,
	LoadEntitySuccess: LoadSurveySuccess,
	LoadEntityFailure: LoadSurveyFailure,
	PreviewEntityRequest: PreviewSurveyEmail,
	PreviewSentSurveyRequest: PreviewSentSurveyRequest,
	CreateEntityRequest: CreateSurveyRequest,
	CreateEntitySuccess: CreateSurveySuccess,
	CreateEntityFailure: CreateSurveyFailure,
	UpdateEntityRequest: UpdateSurveyRequest,
	UpdateEntitySuccess: UpdateSurveySuccess,
	UpdateEntityFailure: UpdateSurveyFailure,
} = surveyEntityActions;

export const SetSurveyShowForm = createActionWithSingleProps<boolean>(`[${ActionPrefix}] Survey Show Form`);
export const OpenSuperDebtorSurveySentMail = createAction(`[${ActionPrefix}] Open SuperDebtorSurvey SentMail`);

export const transferEntitiesActions = createEntityActions<IInvoiceField<ITransferDocumentEntity>[]>(ActionPrefix, 'Transfer Entities');
export const {
	LoadEntityByIdRequest: LoadTransferEntitiesRequest,
	LoadEntitySuccess: LoadTransferEntitiesSuccess,
	LoadEntityFailure: LoadTransferEntitiesFailure,
} = transferEntitiesActions;

export const debtorContactsActions = createEntityActions<IDebtorContact[]>(ActionPrefix, 'Debtor Contacts');
export const {
	LoadEntityByIdRequest: LoadDebtorContactsRequest,
	LoadEntitySuccess: LoadDebtorContactsSuccess,
	LoadEntityFailure: LoadDebtorContactsFailure,
} = debtorContactsActions;

export const senSellerMailActions = createEntityActions<IEmailPreview>(ActionPrefix, 'Send Seller Mail');
export const {
       CreateEntityRequest: SendSellerMailRequest,
       CreateEntitySuccess: SendSellerMailSuccess,
       CreateEntityFailure: SendSellerMailFailure,
} = senSellerMailActions;

export const GetSellerEmailPreview = createAction(`[${ActionPrefix}] Get SellerEmail Preview`, props<{ mailData: IEmailPreview }>());

export const senDebtorMailActions = createEntityActions<IEmailPreview>(ActionPrefix, 'Send Debtor Mail');
export const {
       CreateEntityRequest: SendDebtorMailRequest,
       CreateEntitySuccess: SendDebtorMailSuccess,
       CreateEntityFailure: SendDebtorMailFailure,
} = senDebtorMailActions;

export const GetDebtorEmailPreview = createAction(`[${ActionPrefix}] Get DebtorEmail Preview`, props<{ mailData: IEmailPreview }>());
