
import { ITransfer, IInsuranceDecision, IInvoiceField, ITransferDocumentEntity, ITransferSurvey, ITransferSurveyRow, IPanelInformation } from "apps/middle/src/app/transfers-module/models";
import { ISellerIdentity } from 'apps/middle/src/app/sellers-module/models';
import { ControlValidationState } from 'apps/middle/src/app/shared-module/enums';
import { IUIMessage } from '@aston/foundation';
import { IDebtorContact } from "apps/middle/src/app/debtors-module/models";

import { IStoreEntityStateSlice, createEntitySlice } from '../functions';

export interface ITransferControlState {
	isLoading: boolean;
	isPending: boolean;
	error: any;
	transfer: IStoreEntityStateSlice<ITransfer>;
	transferStatusError: string;
	invoices: IStoreEntityStateSlice<IInvoiceField<ITransferDocumentEntity>[]>;
	insuranceDecision: IStoreEntityStateSlice<IInsuranceDecision>;
	sellerIdentity: IStoreEntityStateSlice<ISellerIdentity>;
	panelInfos: IStoreEntityStateSlice<IPanelInformation>;
	debtorContacts: IStoreEntityStateSlice<IDebtorContact[]>;

	surveysDetails: { [id: string]: ITransferSurvey };
	surveys: IStoreEntityStateSlice<IInvoiceField<ITransferSurveyRow[]>>;

	surveyForm: IStoreEntityStateSlice<ITransferSurvey>;
	surveyShowForm: boolean;
	notification: IUIMessage;
}

export const initialState: ITransferControlState = {
	isLoading: false,
	isPending: false,
	error: null,
	transfer: createEntitySlice(),
	transferStatusError: null,
	invoices: createEntitySlice({ entity: [] }),
	insuranceDecision: createEntitySlice(),
	sellerIdentity: createEntitySlice(),
	panelInfos: createEntitySlice(),
	surveysDetails: {},
	surveys: createEntitySlice({
		entity: {
			state: ControlValidationState.Unknown,
			value: []
		}
	}),
	surveyForm: createEntitySlice(),
	surveyShowForm: false,
	notification: null,
	debtorContacts: createEntitySlice({ entity: [] }),
};
